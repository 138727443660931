import { User } from "./user";

export class AuthorizationResult {
	user!: User;
	isAuthenticated = false;
	isIdentityServerConfigured = false;
	isIdentityServerOnError = false;

	constructor(
		isIdentityServerConfigured: boolean,
		isIdentityServerOnError: boolean,
		isAuthenticated: boolean,
		user?: User
	) {
		this.isIdentityServerConfigured = isIdentityServerConfigured;
		this.isIdentityServerOnError = isIdentityServerOnError;
		this.isAuthenticated = isAuthenticated;

		if (user) this.user = user;
	}

	hasRoles(roles: string[]): boolean {
		if (!this.isIdentityServerConfigured || this.isIdentityServerOnError || !this.isAuthenticated || !this.user)
			return false;

		return this.user.hasRoles(roles);
	}

	canAccessAuthorizedContent(): boolean {
		if (!this.isIdentityServerConfigured || this.isIdentityServerOnError || !this.isAuthenticated) return false;

		return true;
	}
}
