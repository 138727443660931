import { Injectable } from "@angular/core";
import { Observable, of, tap } from "rxjs";
import { AppConfig } from "../types/app-config";

@Injectable({
	providedIn: "root",
})
export class AppConfigService {
	appConfig!: AppConfig;

	initAppConfig(): Observable<AppConfig> {
		return of(AppConfig.createAppConfigFromEnvironment()).pipe(
			tap((iniAppConfig: AppConfig) => (this.appConfig = iniAppConfig))
		);
	}
}
