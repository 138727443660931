import { CommonModule } from "@angular/common";
import { APP_INITIALIZER, InjectionToken, NgModule, inject } from "@angular/core";
import { Observable } from "rxjs";
import { AppConfigService } from "./services/app-config-service.service";
import { AppConfig } from "./types/app-config";

export const APP_CONFIG = new InjectionToken<AppConfig>("APP_CONFIG");

const initializeConfigFn =
	(configService: AppConfigService): (() => Observable<AppConfig>) =>
	() =>
		configService.initAppConfig();

@NgModule({
	imports: [CommonModule],
	providers: [
		{
			provide: APP_INITIALIZER,
			useFactory: initializeConfigFn,
			multi: true,
			deps: [AppConfigService],
		},
		{
			provide: APP_CONFIG,
			useFactory: () => inject(AppConfigService).appConfig,
			deps: [AppConfigService],
		},
	],
})
export class ConfigModule {}
