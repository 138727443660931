import { Roles } from "../constants";
import { Impersonation } from "./impersonation";

export class User {
	id!: string;
	private _familyName!: string;
	private _givenName!: string;
	isAuthenticated = false;
	roles!: string[];
	private _username!: string;
	private _currentRentalId!: string;
	isRegistered!: boolean;
	private _thirdPartyId!: string;

	private _impersonation!: Impersonation;

	constructor(init?: Partial<User>) {
		this._impersonation = Impersonation.retrieveFromCache();
		Object.assign(this, init);
	}

	// Getter Setter FamilyName
	get familyName(): string {
		if (this._impersonation.isImpersonated) return this._impersonation.familyName;
		return this._familyName;
	}
	set familyName(familyName: string) {
		this._familyName = familyName;
	}
	// Getter Setter GivenName
	get givenName(): string {
		if (this._impersonation.isImpersonated) return this._impersonation.givenName;
		return this._givenName;
	}
	set givenName(givenName: string) {
		this._givenName = givenName;
	}

	// Getter Setter CurrentRentalId
	get currentRentalId(): string {
		return this._impersonation.isImpersonated ? this._impersonation.currentRentalId : this._currentRentalId;
	}

	set currentRentalId(rentalId: string) {
		if (this._impersonation.isImpersonated) {
			this._impersonation.currentRentalId = rentalId;
			return;
		}
		this._currentRentalId = rentalId;
	}

	get rentalIds(): string[] {
		return this._impersonation.isImpersonated ? this._impersonation.rentalIds : ([] as string[]);
	}

	// Getter Setter ThirdParty
	get thirdPartyId(): string {
		if (this._impersonation.isImpersonated) return this._impersonation.thirdPartyId;
		return this._thirdPartyId;
	}

	set thirdPartyId(thirdPartyId: string) {
		this._thirdPartyId = thirdPartyId;
	}

	// Getter Setter UserName
	get username(): string {
		if (this._impersonation.isImpersonated) return this._impersonation.username;
		return this._username;
	}

	set username(userName: string) {
		this._username = userName;
	}

	get isImpersonated(): boolean {
		return this._impersonation.isImpersonated;
	}

	get hasAdminRole(): boolean {
		return this.hasRoles(Roles.admin);
	}

	get isLegalPerson(): boolean {
		if (this._impersonation.isImpersonated) return this._impersonation.isLegalPerson;

		return this.hasRoles(Roles.legalPerson);
	}

	hasRoles(roles: string[] | string): boolean {
		if (!this.roles) return false;

		if (typeof roles === "string") return this.roles.includes(roles);

		for (const role of roles) {
			if (this.roles.includes(role)) return true;
		}

		return false;
	}

	impersonateThirdParty(
		currentRentalId: string,
		familyName: string,
		givenName: string,
		roles: string[],
		isImpersonate: boolean,
		isRegistered: boolean,
		rentalIds: string[],
		userName: string,
		thirdPartyId: string
	) {
		const impersonation = new Impersonation({
			currentRentalId: currentRentalId,
			familyName: familyName,
			givenName: givenName,
			isImpersonated: isImpersonate,
			isLegalPerson: roles.includes(Roles.legalPerson),
			isRegistered: isRegistered,
			rentalIds: rentalIds,
			username: userName,
			thirdPartyId: thirdPartyId,
		});

		impersonation.saveInsideCache();

		this._impersonation = impersonation;
	}
}
