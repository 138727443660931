import { AppConfig } from "@e-tenant-hub/shared/config";
import { AuthConfig } from "angular-oauth2-oidc";

export class CodeFlowAuthConfig extends AuthConfig {
	constructor(appConfig: AppConfig, isProduction: boolean) {
		super();
		this.clearHashAfterLogin = false;
		this.clientId = appConfig.clientId;
		this.dummyClientSecret = appConfig.clientSecret;
		this.issuer = `${appConfig.authority}`;
		this.oidc = true;
		this.postLogoutRedirectUri = `${window.location.origin}/signout-callback`;
		this.redirectUri = `${window.location.origin}/signin-callback`;
		this.redirectUriAsPostLogoutRedirectUriFallback = true;
		this.responseType = "code";
		this.scope = "openid profile offline_access etenant.user emh-tenant-api emh-auth-api";
		this.sessionChecksEnabled = false;
		this.showDebugInformation = !isProduction;
		this.silentRefreshRedirectUri = `${window.location.origin}/silent-refresh.html`;
		this.useSilentRefresh = true;
	}
}
