import { Injectable, inject } from "@angular/core";
import {
	ActivatedRouteSnapshot,
	CanActivateFn,
	Params,
	RouterStateSnapshot,
	UrlTree,
	createUrlTreeFromSnapshot,
} from "@angular/router";
import { Observable, map } from "rxjs";
import { AuthService } from "../services";
import { AuthorizationResult } from "../types";

export const authInGuard: CanActivateFn = (
	route: ActivatedRouteSnapshot,
	state: RouterStateSnapshot
): Observable<boolean | UrlTree> => {
	return inject(PermissionsService).isAuthenticated(route, state);
};

export const rolesInGuard: CanActivateFn = (
	route: ActivatedRouteSnapshot,
	state: RouterStateSnapshot
): Observable<boolean | UrlTree> => {
	return inject(PermissionsService).hasInRoles(route, state);
};

export const impersonationForThirdPartyGuard: CanActivateFn = (
	route: ActivatedRouteSnapshot,
	state: RouterStateSnapshot
): Observable<boolean | UrlTree> | boolean | UrlTree => {
	return inject(PermissionsService).isImpersonatedAsThirdParty(route, state);
};

@Injectable({ providedIn: "root" })
export class PermissionsService {
	private readonly unauthorizedUrl = "/unauthorized";
	private readonly serviceUnreachableUrl = "/service-unreachable";
	private readonly forbiddenUrl = "/forbidden";
	private readonly impersonationUrl = "/choose-account";
	private readonly authService = inject(AuthService);

	isAuthenticated(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
		return this.authService.authorizationResult$.pipe(
			map((authorizationResult: AuthorizationResult) => {
				if (!authorizationResult.canAccessAuthorizedContent()) {
					console.debug(
						`Unauthorized to access ${state.url} | User not authenticated --> redirection to unauthorized page`
					);
					return this.redirectToUnauthorizedPage(route, state.url);
				}

				return true;
			})
		);
	}

	hasInRoles(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
		return this.authService.authorizationResult$.pipe(
			map((authorizationResult: AuthorizationResult): boolean | UrlTree => {
				if (!authorizationResult.canAccessAuthorizedContent()) {
					console.warn(
						`Unauthorized to access ${state.url} | User not authenticated --> redirection to unauthorized page`
					);
					return this.redirectToUnauthorizedPage(route, state.url);
				}

				if (!route.data || !authorizationResult.hasRoles(route.data["roles"])) {
					console.warn(
						`Forbidden to access ${state.url} | current scopes not allowed --> redirection to forbidden page`
					);
					return this.redirectToForbiddenPage(route, state.url);
				}

				return true;
			})
		);
	}

	isImpersonatedAsThirdParty(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean | UrlTree> {
		return this.authService.authorizationResult$.pipe(
			map((authorizationResult): boolean | UrlTree => {
				if (!authorizationResult.canAccessAuthorizedContent()) {
					console.warn(
						`Unauthorized to access ${state.url} | User not authenticated --> redirection to unauthorized page`
					);
					return this.redirectToUnauthorizedPage(route, state.url);
				}

				if (authorizationResult.user.hasAdminRole && !authorizationResult.user.isImpersonated) {
					console.warn(
						`Wrong impersonation state to access ${state.url} | User not impersonated --> redirection to impersonation page`
					);
					return this.redirectToImpersonationPage(route);
				}

				return true;
			})
		);
	}

	private redirectToUnauthorizedPage(route: ActivatedRouteSnapshot, returnUrl: string): UrlTree {
		return this.createUrlTree(route, [this.unauthorizedUrl], { returnUrl: returnUrl });
	}

	private redirectToForbiddenPage(route: ActivatedRouteSnapshot, returnUrl: string): UrlTree {
		return this.createUrlTree(route, [this.forbiddenUrl], { returnUrl: returnUrl });
	}

	private redirectToImpersonationPage(route: ActivatedRouteSnapshot): UrlTree {
		return this.createUrlTree(route, [this.impersonationUrl]);
	}

	private createUrlTree(
		route: ActivatedRouteSnapshot,
		commands: string[],
		queryParams?: Params | null | undefined
	): UrlTree {
		return createUrlTreeFromSnapshot(route, commands, queryParams);
	}
}
