import { CommonModule } from "@angular/common";
import { HTTP_INTERCEPTORS, HttpClient } from "@angular/common/http";
import { APP_INITIALIZER, NgModule } from "@angular/core";
import { AppConfigService, ConfigModule } from "@e-tenant-hub/shared/config";
import { UtilsModule } from "@e-tenant-hub/shared/utils";
import { OAuthModule, OAuthStorage, ValidationHandler } from "angular-oauth2-oidc";
import { JwksValidationHandler } from "angular-oauth2-oidc-jwks";
import { AuthHttpClient } from "./http-clients";
import { AuthInterceptor } from "./interceptors";
import { AuthService } from "./services";

export function storageFactory(): OAuthStorage {
	return localStorage;
}
const initializeAuthFn =
	(configService: AppConfigService, authService: AuthService): (() => Promise<boolean>) =>
	() => {
		authService.configure(configService.appConfig);
		return authService.initialize();
	};

@NgModule({
	imports: [CommonModule, ConfigModule, UtilsModule, OAuthModule.forRoot()],
	providers: [
		{ provide: ValidationHandler, useClass: JwksValidationHandler },
		{ provide: OAuthStorage, useFactory: storageFactory },
		{
			provide: APP_INITIALIZER,
			useFactory: initializeAuthFn,
			multi: true,
			deps: [AppConfigService, AuthService],
		},
		{
			provide: AuthHttpClient,
			deps: [HttpClient],
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AuthInterceptor,
			multi: true,
		},
	],
})
export class AuthModule {}
