// import { CacheKeys } from "@e-tenant-hub/shared/utils";

import { CacheKeys } from "@e-tenant-hub/shared/utils";

export class Impersonation {
	currentRentalId = "";
	familyName = "";
	givenName = "";
	isImpersonated = false;
	isLegalPerson = false;
	isRegistered = false;
	rentalIds: string[] = [];
	username = "";
	thirdPartyId = "";

	constructor(init?: Partial<Impersonation>) {
		Object.assign(this, init);
	}

	saveInsideCache(): void {
		const jsonData = JSON.stringify(this);
		localStorage.setItem(CacheKeys.IMPERSONATION, jsonData);
	}

	static resetImpersonationInsideCache(): Impersonation {
		const impersonation = new Impersonation({
			currentRentalId: "",
			familyName: "",
			givenName: "",
			isImpersonated: false,
			isLegalPerson: false,
			isRegistered: false,
			rentalIds: [],
			username: "",
			thirdPartyId: "",
		});

		const jsonData = JSON.stringify(impersonation);
		localStorage.setItem(CacheKeys.IMPERSONATION, jsonData);

		return impersonation;
	}

	static retrieveFromCache(): Impersonation {
		const jsonData = localStorage.getItem(CacheKeys.IMPERSONATION);
		if (jsonData) return JSON.parse(jsonData);

		return Impersonation.resetImpersonationInsideCache();
	}
}
